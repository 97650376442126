

















































import { defineComponent } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'
import { userConnectedHasRole } from '@/composables/UserGrant'

export default defineComponent({
  setup () {
    const canDeleteEventDay = () => userConnectedHasRole('ROLE_DELETE_EVENT_DAY')
    return {
      canDeleteEventDay,
    }
  },
  name: 'deleteEventDay',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    eventDay: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('eventDay', {
      isLoading: 'loading',
    }),
  },
  methods: {
    ...mapActions('eventDay', {
      delete: 'delete',
    }),
    ...mapActions('user', {
      sendSuccessMessage: 'sendSuccessMessage',
    }),
    async deleteEventDay () {
      await this.delete(this.eventDay.id)
      await this.sendSuccessMessage('event_day.delete.deleted')
      this.close()
    },
    close () {
      this.$emit('close')
    },
  },
})
